import React, { FC } from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import { useStaticQuery, graphql } from 'gatsby';
import CoreValues from 'containers/careers/core-values';
import TestimonialArea from 'containers/careers/testimonial-area';
import OurCraft from 'containers/careers/our-craft';
import HiringProcess from 'containers/careers/hiring-process';
import CtaArea from 'containers/careers/cta-area';
import CareersCallout from 'containers/careers/callout';
import CareersAbout from 'containers/careers/about';
import bgCareers from 'assets/images/bg/headers/Careers-Header.jpeg';

const IndexCareersPage: FC<{ location: string }> = ({ location }) => {
  const { careersHeroJson: data } = useStaticQuery(graphql`
    query careersHeroDataQuery {
      careersHeroJson {
        hero {
          title
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <SEO title="Careers" image={bgCareers} />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={data?.hero?.title}
          button={data?.hero?.button}
          hero="careers"
        />
        <CareersCallout />
        <CareersAbout />
        <CoreValues />
        <TestimonialArea />
        <OurCraft />
        <CtaArea />
        <HiringProcess />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexCareersPage;
