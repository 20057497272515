import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import Heading from 'components/ui/heading';
import parse from 'html-react-parser';
import SectionTitle from 'components/ui/section-title';
import {
  SectionContainer,
  SectionContentsContainer,
  IconCardWrapper,
  IconCardBodyWrapper,
  IconWrapper,
} from './core-values.style';
import { graphql, useStaticQuery } from 'gatsby';
import Text from 'components/ui/text';

export interface CoreValuesProps {
  headingStyle?: object;
  cardHeadingStyle?: object;
  textStyle?: object;
}

interface CoreValueData {
  json: {
    values: {
      title: string;
      icon: any;
    }[]
  }
  content: {
    heading?: {
      title: string;
      subtitle: string;
    };
    body: string;
    iconCards: IconCard[];
  };
}

interface IconCard {
  id: string;
  title: string;
  body: string;
  icon?: any;
}

const CoreValues: FC<CoreValuesProps> = ({
  headingStyle,
  cardHeadingStyle,
  textStyle,
}) => {
  const data: CoreValueData = useStaticQuery(graphql`
    query careersPageCoreValuesQuery {
      json: careersJson(id: { eq: "careers-core-values" }) {
        values {
          title
          icon {
            publicURL
          }
        }
      }
      content: careersCoreValuesJson {
        heading {
          subtitle
          title
        }
        body
        iconCards {
          id
          title
          body
        }
      }
    }
  `);
  if (!data.content) {
    return null;
  }

  const { heading, body, iconCards } = data.content;

  iconCards.forEach((card) => {
    const match = data.json.values.find(
      (value) => value.title === card.title.toLowerCase(),
    );
    if (match) {
      card.icon = match.icon;
    }
    return card;
  });

  return (
    <SectionContainer className="left-red-slice">
      {heading && (
        <SectionTitle
          subtitle={heading?.subtitle}
          title={heading?.title}
          {...headingStyle}
        />
      )}
      {body && (
        <Text {...textStyle}>
          <ReactMarkdown>{body}</ReactMarkdown>
        </Text>
      )}
      <SectionContentsContainer>
        {iconCards &&
          iconCards.map((card) => (
            <IconCardWrapper key={card.id}>
              {card.icon && (
                <IconWrapper src={card.icon.publicURL} alt={card.title} />
              )}
              <IconCardBodyWrapper>
                <Heading {...cardHeadingStyle}>{parse(card.title) as string}</Heading>
                <ReactMarkdown source={card.body} />
              </IconCardBodyWrapper>
            </IconCardWrapper>
          ))}
      </SectionContentsContainer>
    </SectionContainer>
  );
};

CoreValues.defaultProps = {
  headingStyle: {
    mb: '50px',
    mt: '50px',
    align: 'center',
  },
  cardHeadingStyle: {
    as: 'h6',
    mb: '10px',
  },
  textStyle: {
    mb: '50px',
  },
};

export default CoreValues;
