import React, { FC } from 'react';
import Image from 'components/image';
import Heading from 'components/ui/heading';
import ReactMarkdown from 'react-markdown';
import Button from 'components/ui/button';
import {
  DepartmentButtonsWrapper,
  DepartmentContentWrapper,
  DepartmentImageWrapper,
  DepartmentWrapper,
} from './our-craft.style';

export interface DepartmentType {
  image: any;
  title: string;
  body: string;
  links: {
    link: string;
    title: string;
  }[];
  buttonStyle: any;
  headingStyle: any;
}

const Department: FC<DepartmentType> = ({
  image,
  title,
  body,
  links,
  buttonStyle,
  headingStyle,
}) => (
  <DepartmentWrapper>
    <DepartmentImageWrapper>
      {image?.childImageSharp && <Image fluid={image.childImageSharp.fluid} />}
    </DepartmentImageWrapper>
    <DepartmentContentWrapper>
      {title && <Heading {...headingStyle}>{title}</Heading>}
      {body && <ReactMarkdown>{body}</ReactMarkdown>}
      <DepartmentButtonsWrapper>
        {links.map((link, idx) => (
          <Button
            key={`${link.title}-${idx}`}
            to={link.link}
            varient="texted"
            {...buttonStyle}
          >
            {link.title}
          </Button>
        ))}
      </DepartmentButtonsWrapper>
    </DepartmentContentWrapper>
  </DepartmentWrapper>
);

Department.defaultProps = {
  buttonStyle: {
    color: 'black',
    m: '10px 0',
  },
  headingStyle: {
    as: 'h5',
    mb: '15px',
  },
};

export default Department;
