import styled from 'styled-components';
import { device } from 'theme';

export const SectionContentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 40px;

  @media ${device.small} {
    grid-template-columns: 1fr;
  }
`;

export const ImageBoxWrapper = styled.div`
  width: 100%;
`;

export const SectionContainer = styled.section`
  width: 100%;
  padding: 50px 100px 50px;
  text-align: center;
`;

export const IconCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.outlines.boxShadow};
  border-radius: ${(props) => props.theme.outlines.borderRadius};
  padding: 25px;
  .gatsby-image-wrapper {
    margin-bottom: 15px;
  }
`;

export const IconWrapper = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 16px;
`;

export const IconCardBodyWrapper = styled.div``;
