import Button from 'components/ui/button';
import styled from 'styled-components';
import { device } from 'theme';

export const Section = styled.section`
  padding: 80px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.medium} {
    padding: 60px 50px;
  }
  @media ${device.small} {
    padding: 40px 25px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 25px;
  margin: 25px;
  background-color: ${(props) => props.theme.colors.primaryAccent};
  color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.outlines.boxShadow};
  border-radius: ${(props) => props.theme.outlines.borderRadius};
  max-width: 500px;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.colors.primaryAccent};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const CTAButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  justify-items: center;
  @media ${device.small} {
    grid-template-columns: 1fr;
  }
`;
