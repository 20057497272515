import styled from 'styled-components';
import { device } from 'theme';

export const TestimonialWrapper = styled.section`
  padding: 100px 50px;
  @media ${device.medium} {
    padding: 80px 50px;
  }
  @media ${device.small} {
    padding: 60px 0;
  }

  .swiper-container,
  .swiper-wrapper {
    position: initial;
  }

  .customized-swiper-button-prev,
  .customized-swiper-button-next {
    position: absolute;
    top: 50%;
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
  }

  .customized-swiper-button-prev {
    left: 0;
    right: auto;
  }

  .customized-swiper-button-next {
    left: auto;
    right: 0;
  }

  .swiper-pagination {
    display: none;
  }

  @media ${device.xsmall} {
    .swiper-pagination {
      display: block;
    }
    .customized-swiper-button-prev,
    .customized-swiper-button-next {
      display: none;
    }
  }
`;

export const TestimonialBodyWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 50px;

  @media ${device.xsmall} {
    padding: 0;
  }
`;
