import RadioGroup from 'components/ui/radio-group';
import SectionTitle from 'components/ui/section-title';
import { graphql, useStaticQuery } from 'gatsby';
import React, { BaseSyntheticEvent, FC, useState } from 'react';
import Department, { DepartmentType } from './department';
import {
  OurCraftWrapper,
  OurCraftControlsWrapper,
  OurCraftButtonsWrapper,
  OurCraftDepartmentWrapper,
} from './our-craft.style';

interface DepartmentNode extends DepartmentType {
  id: string;
  label: string;
}

interface Props {
  titleStyles?: any;
}

interface JSONDep {
  title: string;
  image: any;
}

const OurCraft: FC<Props> = ({ titleStyles }) => {
  const data = useStaticQuery(graphql`
    query careersPageOurCraftQuery {
      json: careersJson(id: { eq: "careers-craft" }) {
        departments {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 571, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
      content: careersCraftJson {
        heading {
          title
          subtitle
        }
        department {
          id
          label
          title
          body
          links {
            link
            title
          }
        }
      }
    }
  `);

  if (!data.content) {
    return null;
  }

  const {
    heading: { title, subtitle },
    department,
  } = data.content;

  department.forEach((dep: DepartmentType) => {
    const match = data.json.departments.find(
      (jsonDep: JSONDep) => jsonDep.title === dep.title.toLowerCase(),
    );
    if (match) {
      (dep).image = match.image;
    }
    return dep;
  });

  const firstDepartment = department[0].id;

  const [selectedOption, setSelectedOption] = useState(firstDepartment);

  return (
    <OurCraftWrapper>
      <OurCraftControlsWrapper>
        <SectionTitle title={title} subtitle={subtitle} {...titleStyles} />
        <OurCraftButtonsWrapper>
          <RadioGroup
            name="select-department"
            selectedValue={selectedOption}
            onChange={(e: BaseSyntheticEvent) => setSelectedOption(e.target.value)}
            options={department.map((dep: DepartmentNode) => ({
              id: dep.id,
              value: dep.id,
              label: dep.label,
            }))}
          />
        </OurCraftButtonsWrapper>
      </OurCraftControlsWrapper>
      <OurCraftDepartmentWrapper>
        {department
          .filter((dep: DepartmentNode) => dep.id === selectedOption)
          .map((dep: DepartmentNode) => (
            <Department key={`departments-${dep.id}`} {...dep} />
          ))}
      </OurCraftDepartmentWrapper>
    </OurCraftWrapper>
  );
};

OurCraft.defaultProps = {
  titleStyles: {
    align: 'center',
    mb: '30px',
  },
};

export default OurCraft;
