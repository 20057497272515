import Callout from 'components/callout';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const CareersCallout = () => {
  const data = useStaticQuery(graphql`
    query careersPageCallout {
      content: careersCalloutJson {
        id
        title
        body
        button {
          link
          title
        }
      }
    }
  `);

  const { content } = data;

  if (!content) {
    return null;
  }

  return <Callout {...content} />;
};

export default CareersCallout;
