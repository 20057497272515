import SectionRightImage from 'components/section-right-image';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const CareersAbout = () => {
  const data = useStaticQuery(graphql`
    query careersPageAboutQuery {
      json: careersJson(id: { eq: "careers-about" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        badge {
          childImageSharp {
            fixed(width: 205, height: 178) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      content: careersAboutJson {
        header {
          title
          subtitle
        }
        body
        button {
          title
          link
        }
      }
    }
  `);

  const about = data.content;

  if (!about) {
    return null;
  }

  return (
    <SectionRightImage
      badge={data.json.badge}
      contents={{
        ...about.header,
        body: about.body,
      }}
      link={about.button}
      image={data.json.image}
      className="top-left-full-shadow-corner"
    />
  );
};

export default CareersAbout;
