import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  TestimonialWrap,
  TestimonialInfo,
  TestimonialMedia,
  TestimonialAuthor,
  AuthorInfo,
  AuthorName,
  AuthorRole,
  AuthorFact,
  Review,
} from './testimonial.style';
import { GatsbyImageProps } from 'gatsby-image';

interface TestimonialProps {
  authorName: string;
  authorRole: string;
  authorFact?: string;
  authorImg: string | GatsbyImageProps;
  review: string;
}

const Testimonial = ({
  authorName,
  authorRole,
  authorFact,
  authorImg,
  review,
}: TestimonialProps) => {
  let authorImage;
  if (authorImg && authorImg.fixed && typeof authorImg.fixed !== 'function') {
    authorImage = (
      <Img fixed={authorImg.fixed} alt={authorName} loading="eager" />
    );
  } else if (typeof authorImg === 'string') {
    authorImage = (
      <img
        src={authorImg}
        className="img-fluid"
        alt={authorName}
        loading="eager"
      />
    );
  }

  return (
    <TestimonialWrap>
      <TestimonialInfo>
        {authorImg && <TestimonialMedia>{authorImage}</TestimonialMedia>}
        <TestimonialAuthor>
          <AuthorInfo>
            {authorName && <AuthorName>{authorName}</AuthorName>}
            {authorRole && <AuthorRole>{authorRole}</AuthorRole>}
            {authorFact && <AuthorFact>{authorFact}</AuthorFact>}
          </AuthorInfo>
        </TestimonialAuthor>
      </TestimonialInfo>
      {review && <Review>{review}</Review>}
    </TestimonialWrap>
  );
};

Testimonial.propTypes = {
  authorImg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  authorName: PropTypes.string,
  authorRole: PropTypes.string,
  authorFact: PropTypes.string,
  review: PropTypes.string,
};

export default Testimonial;
