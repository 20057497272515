import styled from "styled-components";
import { device } from "theme";
import ReactMarkdown from "react-markdown";

export const TestimonialWrap = styled.div`
  position: relative;
  min-height: 500px;
  padding: 31px 25px 47px;
  border-radius: 5px;
  margin: 20px 15px 0px;
`;

export const TestimonialInfo = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 22px;
  @media ${device.xsmall} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const TestimonialMedia = styled.div`
  margin-right: 30px;
  flex-shrink: 0;
  img {
    border-radius: 50%;
  }
  @media ${device.xsmall} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const TestimonialAuthor = styled.div`
  flex-grow: 1;
  @media ${device.xsmall} {
    text-align: center;
  }
`;

export const AuthorInfo = styled.div`
  font-family: ${(props) => props.theme.fontFamily.heading};
  line-height: ${(props) => props.theme.lineHeights.hero};
  font-weight: ${(props) => props.theme.fontWeight.heading};
  color: ${(props) => props.theme.colors.white};
  @media ${device.xsmall} {
    text-align: center;
    justify-content: center;
  }
`;

export const AuthorName = styled.h6`
  font-family: ${(props) => props.theme.fontFamily.heading};
  line-height: ${(props) => props.theme.lineHeights.hero};
  font-weight: ${(props) => props.theme.fontWeight.heading};
  font-size: 16px;
  color: #fff;
`;

export const AuthorRole = styled.div``;

export const AuthorFact = styled(ReactMarkdown)``;

export const Review = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  margin-left: 120px;
  @media ${device.small} {
    margin-left: 0;
  }
`;
