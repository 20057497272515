import styled from 'styled-components';
import { device } from 'theme';

export const OurCraftWrapper = styled.section`
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
  @media ${device.small} {
    padding: 50px 25px;
    grid-template-columns: 1fr;
  }
`;

export const OurCraftControlsWrapper = styled.div``;

export const OurCraftButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media ${device.large} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.xsmall} {
    grid-template-columns: 1fr;
  }
`;

export const OurCraftDepartmentWrapper = styled.div``;

export const DepartmentButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const DepartmentWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  @media ${device.small} {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }
`;
export const DepartmentImageWrapper = styled.div``;
export const DepartmentContentWrapper = styled.div``;
