import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import Heading from 'components/ui/heading';
import { CTAButtonsWrapper, Section, StyledButton } from './cta-area.style';
import ReactMarkdown from 'react-markdown';

interface CTA {
  link: string;
  title: string;
}

const CtaArea = ({
  headingStyle,
  btnStyle,
}: {
  headingStyle: object;
  btnStyle: object;
}) => {
  const data = useStaticQuery(graphql`
    query careerPageCtaQuery {
      content: careersCtaJson {
        title
        body
        buttons {
          title
          link
        }
      }
    }
  `);
  if (!data.content) {
    return null;
  }

  const { title, body, buttons } = data.content;
  return (
    <Section className="top-left-full-shadow-corner">
      <Heading {...headingStyle}>{parse(title) as string}</Heading>
      {body && <ReactMarkdown>{body}</ReactMarkdown>}
      <CTAButtonsWrapper>
        {buttons.map((button: CTA, idx: number) => (
          <StyledButton
            key={`${button.title}-${idx}`}
            to={button.link}
            {...btnStyle}
          >
            {button.title}
          </StyledButton>
        ))}
      </CTAButtonsWrapper>
    </Section>
  );
};

CtaArea.propTypes = {
  headingStyle: PropTypes.object,
};

CtaArea.defaultProps = {
  headingStyle: {
    as: 'h3',
    fontweight: 400,
    mb: '30px',
    textalign: 'center',
  },
  btnStyle: {
    varient: 'texted',
    m: '15px 0',
  },
};

export default CtaArea;
