import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import SwiperSlider from 'components/ui/swiper';
import Testimonial from 'components/testimonial';
import { TestimonialWrapper, TestimonialBodyWrapper } from './section.style';
import leftArrow from 'assets/images/Arrows-Left.png';
import rightArrow from 'assets/images/Arrows-Right.png';
import Heading from 'components/ui/heading';

const TestimonialArea = ({ sectionTitleStyle, slider, sliderStyle }) => {
  const data = useStaticQuery(graphql`
    query careersPageTestimonialQuery {
      authors: allAuthorsJson {
        nodes {
          name
          image {
            childImageSharp {
              fixed(width: 90, height: 90, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }

      content: careersTestimonialJson {
        title
        teamMembers {
          name
          role
          randomFact
          testimonial
        }
      }
    }
  `);
  if (!data.content) {
    return null;
  }

  const { title, teamMembers } = data.content;

  teamMembers.forEach((teamMember) => {
    const match = data.authors.nodes.find(
      (author) => author.name === teamMember.name,
    );
    if (match) {
      teamMember.image = match.image;
    }
    return teamMember;
  });

  return (
    <TestimonialWrapper className="top-black-grey-gradient">
      <TestimonialBodyWrapper>
        <Heading {...sectionTitleStyle}>{title}</Heading>
        <SwiperSlider {...sliderStyle} settings={slider}>
          {teamMembers.map((member, i) => (
            <div key={`member-${i}`}>
              <Testimonial
                authorName={member.name}
                authorRole={member.role}
                authorFact={member.randomFact}
                authorImg={member.image && member.image.childImageSharp}
                review={member.testimonial}
              />
            </div>
          ))}
        </SwiperSlider>
      </TestimonialBodyWrapper>
    </TestimonialWrapper>
  );
};

TestimonialArea.propTypes = {
  sliderStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
  slider: PropTypes.object,
};

TestimonialArea.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    color: 'white',
    textalign: 'center',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  slider: {
    loop: true,
    slidesPerView: 2,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      1110: {
        slidesPerView: 2,
      },
    },
    navigation: {
      nextEl: '.customized-swiper-button-next',
      prevEl: '.customized-swiper-button-prev',
    },
    // eslint-disable-next-line
    renderPrevButton: () => (
      <button className="customized-swiper-button-prev">
        <img src={leftArrow} alt="Previous Slide" />
      </button>
    ),
    // eslint-disable-next-line
    renderNextButton: () => (
      <button className="customized-swiper-button-next">
        <img src={rightArrow} alt="Next Slide" />
      </button>
    ),
  },
  sliderStyle: {
    pagination: {
      mt: '28px',
    },
  },
};

export default TestimonialArea;
