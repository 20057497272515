import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import StepsSection from 'components/steps-section';

interface Step {
  number: number;
  content: {
    title: string;
    body: string;
  }
}

const HiringProcess = () => {
  const data = useStaticQuery(graphql`
    query CareerPageHiringProcessQuery {
      content: careersHiringProcessJson {
        heading {
          title
          subtitle
        }
        steps {
          number
          content {
            title
            body
          }
        }
      }
    }
  `);

  if (!data.content) {
    return null;
  }

  const {
    heading: { title, subtitle },
    steps,
  } = data.content;

  const displayedSteps = steps.map((step: Step) => ({
    ...step.content,
    indicator: step.number,
  }));

  return (
    <StepsSection title={title} subtitle={subtitle} steps={displayedSteps} />
  );
};

export default HiringProcess;
